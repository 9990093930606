import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactComponent as MottuLogo } from '../../mottu_logo.svg';
import { ReactComponent as RouteSolidIcon } from '../assets/route-solid.svg';
// Components
import MaterialButton from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import moment from 'moment';
import { Droppable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
import { scroller } from 'react-scroll';
import ReactTooltip from 'react-tooltip';
import Button from '../../common/components/Button';
import PeriodicGetter from '../../fulfillments/components/PeriodicGetter';
import RouteDetailsModal from '../components/RouteDetailsModal';
import { VehicleTypes } from '../constants/VehicleTypes';
import VisitItem from './VisitItem';
// Composers
import { connect } from 'react-redux';
//Actions
import * as Actions from '../actions';
//Selectors
import * as Selectors from '../selectors';
//Style
import './RouteItem.css';
//Helpers
import { quantityConverter } from '../../common/commonHelpers';

const RouteItem = ({
  route,
  color,
  handleEditRoute,
  handleMoveRoute,
  numberOfRoutes,
  visitById,
  taskById,
  timeWindowById,
  driverById,
  vehicleById,
  driverLoading,
  vehicleLoading,
  routeLoading,
  estimatingThirdPartyDeliveryRoute,
  thirdPartyDeliveryRouteLoading,
  allDrivers,
  handleSearchDrivers,
  allVehicles,
  handleSearchVehicles,
  selectedVisitId,
  handleDeleteRoute,
  handleStartThirdPartyDeliveryRoute,
  handleestimateThirdPartyDeliveryRoute,
  handlerefreshThirdPartyDeliveryRoute,
  handlecancelThirdPartyDeliveryRoute,
  handleReorderVisitsInRoute,
  handleVisitClick,
  handleGetLocationsByVisit,
  locationsByVisitId,
  handleClickRoute,
}) => {
  useEffect(() => {
    if (route.visits.findIndex(id => id === selectedVisitId) > -1) {
      scroller.scrollTo(`visit-scroll-${selectedVisitId}`, {
        duration: 500,
        smooth: 'easeInOutQuart',
        containerId: 'scrollable-route-list',
        offset: -150,
        isDynamic: true,
      });
    }
  }, [selectedVisitId, route.visits]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [
    openStartThirdPartyDeliveryDialog,
    setopenStartThirdPartyDeliveryDialog,
  ] = React.useState(false);

  const handleClickopenStartThirdPartyDeliveryDialog = routeId => {
    if (!isInvalidThirdPartyDeliveryRoute()) {
      handleestimateThirdPartyDeliveryRoute(routeId);
    }
    setopenStartThirdPartyDeliveryDialog(true);
  };

  const handleCloseStartThirdPartyDeliveryDialog = () => {
    setopenStartThirdPartyDeliveryDialog(false);
  };

  const handlecreateThirdPartyDeliveryRoute = routeId => {
    handleStartThirdPartyDeliveryRoute(routeId);
    setOpenPopup(false);
    setopenStartThirdPartyDeliveryDialog(false);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);

    // Get drivers and vehicles from API
    handleSearchDrivers();
    handleSearchVehicles();
  };
  const driver = driverById(route.driver);
  const vehicle = vehicleById(route.vehicle);
  const sortedVisits = _.sortBy(
    route.visits.map(visitId => visitById(visitId)),
    ['position'],
  );

  const handleConfirmSetPlanned = e => {
    const decision = window.confirm(
      'Tem certeza que deseja confirmar essa rota como planejada? Após essa decisão, não será possível cancelar a ação!',
    );

    decision && handleEditRoute(route.id, { planned: e.target.checked });
  };

  const handleTogglePopup = () => {
    setOpenPopup(prevOpen => !prevOpen);
  };

  const handleClosePopup = e => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }

    setOpenPopup(false);
  };

  const ThirdPartyDeliveryOrderStatus = {
    created: 'Criado',
    accepted: 'Aceito',
    arrivedAtDistributionCenter: 'Chegou no centro de distribuição',
    takenOut: 'Retirado',
    arrivedAtDeliveryAddress: 'Chegou na entrega',
    delivered: 'Entregue',
    cancelled: 'Cancelado',
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenPopup(false);
    }
  }

  const thirdPartyDeliveryMaxWeight = 10000;
  const isInvalidThirdPartyDeliveryRoute = () =>
    route.totalWeight > thirdPartyDeliveryMaxWeight;

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(openPopup);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && openPopup === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = openPopup;
  // }, [openPopup]);

  const isAvalableToUpdateRoute =
    moment(route.date).diff(moment(), 'days') >= 0;

  const getRouteState = {
    open: 'Não iniciada',
    pending: 'Pendente',
    in_progress: 'Em progresso',
    complete: 'Completa',
  };

  const getRouteColorState = {
    open: 'route-item-state-color-alert',
    pending: 'route-item-state-color-inroute',
    in_progress: 'route-item-state-color-inroute',
    complete: 'route-item-state-color-finished',
  };

  const ThirdPartyDeliveryDriver = 'Mottu*';

  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  return (
    <>
      {driver.name === ThirdPartyDeliveryDriver &&
      route.thirdPartyDeliveryStatus &&
      route.thirdPartyDeliveryStatus != 'cancelled' &&
      route.thirdPartyDeliveryStatus != 'delivered' &&
      !route.completedAt &&
      route.plannedAt ? (
        <PeriodicGetter
          countdownStartValue={45}
          handleGet={() => handlerefreshThirdPartyDeliveryRoute(route.id)}
          loading={thirdPartyDeliveryRouteLoading}
          invisible={true}
        />
      ) : (
        ''
      )}
      {infoModalIsOpen ? (
        <RouteDetailsModal
          handleModalClose={() => setInfoModalIsOpen(false)}
          route={route}
          visits={sortedVisits}
          handleGetLocationsByVisit={handleGetLocationsByVisit}
          locationsByVisitId={locationsByVisitId}
          driverById={driverById}
          vehicleById={vehicleById}
          taskById={taskById}
          timeWindowById={timeWindowById}
        />
      ) : (
        ''
      )}
      {modalIsOpen ? (
        <Modal
          isOpen={modalIsOpen}
          className="route-modal-style"
          onRequestClose={() => setModalIsOpen(false)}
          ariaHideApp={false}
        >
          <div className="route-modal-title">
            <p>Editor de Rota</p>
          </div>
          <div className="route-modal-edit-content">
            <div className="route-modal-edit-content-line">
              <p>Selecionar Motorista</p>
              <select
                onChange={e => {
                  var routeParams = { driverId: e.target.value };
                  if (
                    e.target.selectedOptions &&
                    e.target.selectedOptions.length > 0 &&
                    e.target.selectedOptions[0].text ===
                      ThirdPartyDeliveryDriver
                  ) {
                    routeParams = { ...routeParams, vehicleId: '' };
                  }
                  handleEditRoute(route.id, routeParams);
                }}
                value={route.driver || ''}
              >
                <option value={''}>{'- -'}</option>
                {allDrivers.map((d, i) => {
                  return (
                    <option key={i} value={d.id}>
                      {d.name}
                    </option>
                  );
                })}
              </select>
              <div className="route-modal-edit-spinner">
                {driverLoading ? (
                  <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                ) : null}
              </div>
            </div>

            <div className="route-modal-edit-content-line">
              <p>Selecionar Veículo</p>
              <select
                onChange={e =>
                  handleEditRoute(route.id, { vehicleId: e.target.value })
                }
                value={
                  (driver &&
                    driver.name !== ThirdPartyDeliveryDriver &&
                    route.vehicle) ||
                  ''
                }
                disabled={driver.name === ThirdPartyDeliveryDriver}
              >
                <option value={''}>{'- -'}</option>
                {allVehicles.map((v, i) => {
                  return (
                    <option key={i} value={v.id}>
                      {v.licensePlate}
                    </option>
                  );
                })}
              </select>
              <div className="route-modal-edit-spinner">
                {vehicleLoading ? (
                  <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                ) : null}
              </div>
            </div>
            <div>
              <Button
                text={'Deletar Rota'}
                disabled={route.visits.length !== 0}
                buttonType={'button-secondary'}
                onClick={() => handleDeleteRoute(route.id)}
                loading={routeLoading}
              />
            </div>
            <div className="route-item-modal-loading">
              {routeLoading ? (
                <i
                  className="fa fa-spinner fa-pulse fa-1x"
                  aria-hidden="true"
                />
              ) : null}
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}

      <Droppable
        droppableId={`route-list-${route.id}`}
        isDropDisabled={!!route.completedAt}
      >
        {provided => (
          <div
            className="route-item"
            ref={provided.innerRef}
            onClick={() => {
              handleVisitClick(route.visits);
              handleClickRoute(route.id);
            }}
          >
            <button
              onClick={() => handleMoveRoute(route.id, route.position + 1)}
              className="route-item-edit"
              disabled={
                !isAvalableToUpdateRoute ||
                !!route.plannedAt ||
                route.position >= numberOfRoutes
              }
              data-for={`route-page-tooltip-increase-position-alert-for-route-${route.id}`}
              data-tip=""
            >
              <ReactTooltip
                id={`route-page-tooltip-increase-position-alert-for-route-${route.id}`}
              >
                <p>Mudar a posição da rota</p>
              </ReactTooltip>

              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </button>

            <button
              onClick={() => handleMoveRoute(route.id, route.position - 1)}
              className="route-item-edit"
              disabled={
                !isAvalableToUpdateRoute ||
                !!route.plannedAt ||
                route.position <= 1
              }
              data-for={`route-page-tooltip-decrease-position-alert-for-route-${route.id}`}
              data-tip=""
            >
              <ReactTooltip
                id={`route-page-tooltip-decrease-position-alert-for-route-${route.id}`}
              >
                <p>Mudar a posição da rota</p>
              </ReactTooltip>

              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </button>

            <button
              onClick={() => handleOpenModal()}
              className="route-item-edit"
              disabled={!isAvalableToUpdateRoute || !!route.plannedAt}
              data-for={`route-page-tooltip-edit-alert-for-route-${route.id}`}
              data-tip=""
            >
              <ReactTooltip
                id={`route-page-tooltip-edit-alert-for-route-${route.id}`}
              >
                <p>Editar Rota</p>
              </ReactTooltip>

              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>

            <span
              data-tip=""
              data-for={`route-item-tooltip-info-see-information-button-${route.id}`}
            >
              <button
                onClick={() => setInfoModalIsOpen(true)}
                className={'route-item-info-show'}
              >
                <ReactTooltip
                  id={`route-item-tooltip-info-see-information-button-${route.id}`}
                >
                  <p>{'Informaçoes sobre essa rota'}</p>
                </ReactTooltip>
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </button>
            </span>

            <button
              onClick={() => handleReorderVisitsInRoute(route.id)}
              className="route-item-edit"
              disabled={
                routeLoading || !isAvalableToUpdateRoute || !!route.plannedAt
              }
              data-tip=""
              data-for={`route-item-tooltip-refresh-visits-${route.id}`}
            >
              <ReactTooltip
                id={`route-item-tooltip-refresh-visits-${route.id}`}
              >
                <p>Reordenar Visitas</p>
              </ReactTooltip>

              <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
            </button>

            <div className="route-item-info-line">
              <div>
                <input
                  type="checkbox"
                  onChange={e => handleConfirmSetPlanned(e)}
                  checked={!!route.plannedAt}
                  disabled={!!route.plannedAt || !route.driver || routeLoading}
                />
                <p className="route-item-info-line-p-content">{'Planejada'}</p>
              </div>
              <div className="route-item-info-line-centered">
                <p className="route-item-info-line-bold route-item-info-line-p-content">
                  ID:
                </p>
                <p className="route-item-info-line-p-content">{route.id}</p>
              </div>
            </div>

            <div
              data-tip=""
              data-for="route-item-tooltip-info-status"
              className={`route-item-info-line route-item-state-properties ${
                getRouteColorState[route.state]
              }`}
            >
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              <ReactTooltip id="route-item-tooltip-info-status">
                <p>Estado da Rota</p>
              </ReactTooltip>
              <p className="route-item-info-line-p-content">
                {getRouteState[route.state]}
              </p>
            </div>

            <div className="route-item-info-line">
              <div
                className="route-info-driver"
                data-tip=""
                data-for="route-item-tooltip-info-driver"
              >
                <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                {driver.name === ThirdPartyDeliveryDriver ? (
                  <div>
                    <MottuLogo
                      className={`third-party-delivery-logo${
                        route.thirdPartyDeliveryStatus ? '' : ' disabled'
                      }`}
                    />
                  </div>
                ) : (
                  <p className="route-item-info-line-p-content">
                    {driver.name}
                  </p>
                )}

                <ReactTooltip id="route-item-tooltip-info-driver">
                  <p>
                    {driver.name === ThirdPartyDeliveryDriver
                      ? 'Entregador da Mottu'
                      : 'Nome do Motorista'}
                  </p>
                </ReactTooltip>
              </div>
              {driver.name === ThirdPartyDeliveryDriver ? (
                <div
                  data-tip=""
                  data-for="route-item-tooltip-create-third-party-delivery-order"
                >
                  <MaterialButton
                    ref={anchorRef}
                    disabled={!route.plannedAt}
                    className="third-party-delivery-create-order-button"
                    aria-controls={openPopup ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleTogglePopup}
                  >
                    <RouteSolidIcon className="route-icon" />
                  </MaterialButton>
                  <Popper
                    open={openPopup}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="right-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: 'left top',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClosePopup}>
                            <MenuList
                              autoFocusItem={openPopup}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                disabled={
                                  route.thirdPartyDeliveryStatus ||
                                  route.visits.length === 0
                                }
                                onClick={() =>
                                  handleClickopenStartThirdPartyDeliveryDialog(
                                    route.id,
                                  )
                                }
                              >
                                Iniciar
                              </MenuItem>
                              <Dialog
                                open={openStartThirdPartyDeliveryDialog}
                                onClose={
                                  handleCloseStartThirdPartyDeliveryDialog
                                }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {isInvalidThirdPartyDeliveryRoute()
                                    ? 'Ops, temos um problema...'
                                    : 'Tem certeza que deseja abrir um pedido Mottu?'}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    {estimatingThirdPartyDeliveryRoute ? (
                                      <i
                                        className="fa fa-spinner fa-pulse fa-1x"
                                        aria-hidden="true"
                                      />
                                    ) : isInvalidThirdPartyDeliveryRoute() ? (
                                      `A rota atual ultrapassa a capacidade máxima de ${Math.round(
                                        thirdPartyDeliveryMaxWeight / 1000,
                                      )}kg para pedidos Mottu.`
                                    ) : (
                                      <span>
                                        A rota atual tem um tempo total estimado
                                        de{' '}
                                        {Math.floor(
                                          route.thirdPartyDeliveryTotalEta / 60,
                                        )}{' '}
                                        minutos e um custo de R${' '}
                                        {route.thirdPartyDeliveryTotalCost}. Tem
                                        certeza que deseja criar um pedido
                                        Mottu? Esta ação alocará um motorista.
                                      </span>
                                    )}
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  {isInvalidThirdPartyDeliveryRoute() ? (
                                    <MaterialButton
                                      onClick={
                                        handleCloseStartThirdPartyDeliveryDialog
                                      }
                                      color="primary"
                                      disabled={
                                        estimatingThirdPartyDeliveryRoute
                                      }
                                    >
                                      OK
                                    </MaterialButton>
                                  ) : (
                                    <div>
                                      <MaterialButton
                                        onClick={
                                          handleCloseStartThirdPartyDeliveryDialog
                                        }
                                        color="primary"
                                        disabled={
                                          estimatingThirdPartyDeliveryRoute
                                        }
                                      >
                                        Cancelar
                                      </MaterialButton>
                                      <MaterialButton
                                        onClick={() =>
                                          handlecreateThirdPartyDeliveryRoute(
                                            route.id,
                                          )
                                        }
                                        color="primary"
                                        autoFocus
                                        disabled={
                                          estimatingThirdPartyDeliveryRoute
                                        }
                                      >
                                        Criar Pedido
                                      </MaterialButton>
                                    </div>
                                  )}
                                </DialogActions>
                              </Dialog>
                              {/* <MenuItem
                                disabled={route.thirdPartyDeliveryStatus != "dropped"}
                                onClick={() => {
                                  handleredoThirdPartyDeliveryRoute(route.id);
                                  setOpenPopup(false);
                                }}
                              >
                                Refazer
                              </MenuItem> */}
                              <MenuItem
                                onClick={() => {
                                  handlerefreshThirdPartyDeliveryRoute(
                                    route.id,
                                  );
                                  setOpenPopup(false);
                                }}
                              >
                                Atualizar
                              </MenuItem>
                              <MenuItem
                                disabled={
                                  !route.thirdPartyDeliveryStatus ||
                                  route.thirdPartyDeliveryStatus ===
                                    'finished' ||
                                  route.thirdPartyDeliveryStatus ===
                                    'cancelled' ||
                                  route.thirdPartyDeliveryStatus ===
                                    'cancelledWithCarge' ||
                                  route.thirdPartyDeliveryStatus ===
                                    'droppedThenCancelled'
                                }
                                onClick={() => {
                                  handlecancelThirdPartyDeliveryRoute(route.id);
                                  setOpenPopup(false);
                                }}
                              >
                                Cancelar
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <ReactTooltip id="route-item-tooltip-create-third-party-delivery-order">
                    <p>Roteiro Mottu</p>
                  </ReactTooltip>
                </div>
              ) : (
                ''
              )}
            </div>

            <div
              className="route-item-info-line"
              data-tip=""
              data-for="route-item-tooltip-info-vehicle"
            >
              <i
                className={`fa fa-${
                  driver.name === ThirdPartyDeliveryDriver
                    ? 'motorcycle'
                    : vehicle.vehicleType &&
                      VehicleTypes[vehicle.vehicleType.slug]
                    ? vehicle.vehicleType.slug
                    : 'car'
                }`}
                aria-hidden="true"
              ></i>
              <p className="route-item-info-line-p-content">
                {driver.name === ThirdPartyDeliveryDriver ? (
                  thirdPartyDeliveryRouteLoading ? (
                    <i
                      className="fa fa-spinner fa-pulse fa-1x"
                      aria-hidden="true"
                    />
                  ) : (
                    route.thirdPartyDeliveryStatus && (
                      <span
                        className={`third-party-delivery-status-${route.thirdPartyDeliveryStatus.replace(
                          /[A-Z]/g,
                          m => '-' + m.toLowerCase(),
                        )}`}
                      >
                        {
                          ThirdPartyDeliveryOrderStatus[
                            route.thirdPartyDeliveryStatus
                          ]
                        }
                      </span>
                    )
                  )
                ) : (
                  vehicle.licensePlate
                )}
              </p>
              <ReactTooltip id="route-item-tooltip-info-vehicle">
                <p>
                  {driver.name === ThirdPartyDeliveryDriver
                    ? 'Estado do Pedido Mottu'
                    : 'Placa do Veículo'}
                </p>
              </ReactTooltip>
            </div>

            <div className="route-item-info-line">
              <i className="fa fa-tag" aria-hidden="true"></i>
              <div
                style={{
                  width: '100px',
                  height: '10px',
                  marginLeft: '10px',
                  backgroundColor: `${color}`,
                }}
              />
            </div>
            <div className="route-item-info-line route-item-info-line-space-around">
              <div
                className="route-item-info-line"
                data-tip=""
                data-for="route-item-tooltip-important-time-to-delivery"
              >
                <i className="fa fa-clock-o" aria-hidden="true"></i>
                <p className="route-item-info-line-p-content">{`${Math.floor(
                  route.totalDeliveryTime / 60,
                )} min`}</p>
                <ReactTooltip id="route-item-tooltip-important-time-to-delivery">
                  <p>Tempo de Entrega</p>
                </ReactTooltip>
              </div>

              <div
                className="route-item-info-line"
                data-tip=""
                data-for="route-item-tooltip-info-time-to-prepare"
              >
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <p className="route-item-info-line-p-content">{`${sortedVisits.reduce(
                  (r, visit) => r + visit.estimatedPreparationTime,
                  0,
                )} min`}</p>
                <ReactTooltip id="route-item-tooltip-info-time-to-prepare">
                  <p>Tempo de Preparação Total</p>
                </ReactTooltip>
              </div>

              <div
                className="route-item-info-line"
                data-tip=""
                data-for="route-item-tooltip-important-weight"
              >
                <i className="fa fa-balance-scale" aria-hidden="true"></i>
                <p className="route-item-info-line-p-content">
                  {quantityConverter(route.totalWeight, 'g')}
                </p>
                <ReactTooltip id="route-item-tooltip-important-weight">
                  <p>Peso</p>
                </ReactTooltip>
              </div>

              <div
                className="route-item-info-line"
                data-tip=""
                data-for="route-item-tooltip-important-max-leave-by-time"
              >
                <i className="fa fa-plane" aria-hidden="true"></i>
                <p className="route-item-info-line-p-content">{`${
                  route.maxLeaveByTime
                    ? moment(route.maxLeaveByTime)
                        .utcOffset('-0300')
                        .format('HH:mm')
                    : 'N/A'
                }`}</p>
                <ReactTooltip id="route-item-tooltip-important-max-leave-by-time">
                  <p>Horário Máximo de Saída</p>
                </ReactTooltip>
              </div>
            </div>
            <div onClick={e => e.stopPropagation()}>
              {sortedVisits.map((visit, index) => (
                <VisitItem
                  key={visit.id}
                  index={index}
                  visitId={visit.id}
                  selectedVisitId={selectedVisitId}
                  isAvailableUpdate={
                    !(!isAvalableToUpdateRoute || !!route.plannedAt)
                  }
                  onVisitClick={() => handleVisitClick([visit.id])}
                />
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    numberOfRoutes: Selectors.routeListSelector(state).length,
    visitById: id => Selectors.visitByIdSelector(state, id),
    driverById: id => Selectors.driverByIdSelector(state, id),
    vehicleById: id => Selectors.vehicleByIdSelector(state, id),
    taskById: id => Selectors.taskByIdSelector(state, id),
    timeWindowById: id => Selectors.timeWindowByIdSelector(state, id),
    routeLoading: Selectors.routesLoadingSelector(state),
    estimatingThirdPartyDeliveryRoute:
      Selectors.estimatingThirdPartyDeliveryRouteSelector(state),
    thirdPartyDeliveryRouteLoading:
      Selectors.thirdPartyDeliveryRouteLoadingSelector(state),
    allDrivers: Selectors.AllDriversSelector(state),
    allVehicles: Selectors.AllVehiclesSelector(state),
    locationsByVisitId: id => Selectors.locationsByVisitIdSelector(state, id),
  };
};

const mapDispatchToProps = (dispatch, stateProps) => {
  return {
    handleEditRouteFilters: searchParams => {
      dispatch(Actions.editRouteFilters(searchParams));
    },
    handleSearchDrivers: searchParams => {
      dispatch(Actions.searchDrivers(searchParams));
    },
    handleSearchVehicles: searchParams => {
      dispatch(Actions.searchVehicles(searchParams));
    },
    handleEditRoute: (id, editParams) => {
      dispatch(Actions.editRoute(id, editParams));
    },
    handleMoveRoute: (id, desiredPosition) => {
      dispatch(Actions.sortRoute(id, desiredPosition));
    },
    handleDeleteRoute: routeId => {
      dispatch(Actions.deleteRoute(routeId));
    },
    handleStartThirdPartyDeliveryRoute: routeId => {
      dispatch(Actions.createThirdPartyDeliveryRoute(routeId));
    },
    handleestimateThirdPartyDeliveryRoute: routeId => {
      dispatch(Actions.estimateThirdPartyDeliveryRoute(routeId));
    },
    // handleredoThirdPartyDeliveryRoute: (routeId) => {
    //   dispatch(Actions.redoThirdPartyDeliveryRoute(routeId));
    // },
    handlerefreshThirdPartyDeliveryRoute: routeId => {
      dispatch(Actions.refreshThirdPartyDeliveryRoute(routeId));
    },
    handlecancelThirdPartyDeliveryRoute: routeId => {
      dispatch(Actions.cancelThirdPartyDeliveryRoute(routeId));
    },
    handleReorderVisitsInRoute: id => {
      dispatch(Actions.reorderVisitsInRoute(id));
    },
    handleGetLocationsByVisit: visitId => {
      dispatch(Actions.getLocationsByVisit(visitId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteItem);
