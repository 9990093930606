// @flow
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
// Components
import { LinePlot } from '../../common';
// Composers
import { connect } from 'react-redux';
// Import Actions
import * as FraudAction from '../actions';
// Selectors
import * as FraudSelectors from '../selectors';
// Types
import type { Dispatch } from '../../common';
//Styles
import './UserFraud.css';

type Props = {
  orderNumber: string,
  user: User,
  completedOrders: Array<any>,
  completedOrdersPrice: Array<number>,
  canceledOrders: Array<any>,
  isLoadingUser: boolean,
  getUser: (orderNumber: string) => void,
};

const UserFraud = ({
  orderNumber,
  user,
  completedOrders,
  completedOrdersPrice,
  canceledOrders,
  isLoadingUser,
  getUser,
}: Props) => {
  useEffect(() => {
    if (orderNumber) {
      getUser(orderNumber);
    }
  }, [orderNumber]);

  const formatXAxis = tickItem => {
    return moment(tickItem).utcOffset('-0300').format('DD/MM/YY');
  };

  const formatYAxis = tickItem => {
    return tickItem.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const firstOrder = useMemo(() => _.first(completedOrders), [completedOrders]);

  const data = useMemo(() => {
    const chartData = [];

    if (completedOrders && completedOrders.length > 0) {
      const recentOrders = _.takeRight(completedOrders, 30);
      const dates = recentOrders.map(o =>
        moment(o.date).utcOffset('-0300').startOf('day'),
      );

      const tempDate = _.first(dates) ? _.first(dates).clone() : null;

      while (dates.length > 1 && tempDate.diff(_.last(dates), 'days') <= 0) {
        const tempOrders = _.filter(
          recentOrders,
          o => moment(o.date).startOf('day').diff(tempDate, 'days') === 0,
        );

        const price = tempOrders.reduce(
          (previousValue, currentValue) =>
            previousValue + parseFloat(currentValue.value),
          0,
        );

        chartData.push({ date: tempDate.format(), price });
        tempDate.add(1, 'day');
      }
    }

    return chartData;
    //parse to primitive
  }, [JSON.stringify(completedOrders)]);

  if (!orderNumber) return null;
  if (_.isEmpty(user) || isLoadingUser) return null;

  const noGraphData = data.length === 0 ? 'user-fraud-no-graph' : '';

  return (
    <div className="user-fraud-main">
      <div className="user-fraud-content">
        <div className={`user-fraud-main-container ${noGraphData}`}>
          <h3>Estatísticas do Cliente</h3>

          <div className="address-item-line">
            <div>
              <b>Nome do cliente: </b> {user.firstName} {user.lastName}
            </div>

            <div>
              <b>Email do cliente: </b> {user.email}
            </div>

            <div>
              <b>Convidado por: </b> {user.invitedBy}
            </div>

            <div>
              <b>Conta criada: </b>{' '}
              {moment(user.createdAt).utcOffset('-0300').format('LLLL')}
            </div>

            <div>
              <b>CPF: </b> {user.cpf.value} foi usado por{' '}
              {user.cpf.otherUsers.length} outros usuários
            </div>

            <div>
              <b>Número de endereços cadastrados: </b> {user.addresses.length}
            </div>

            <div>
              <b>Número de pedidos completos: </b> {completedOrders.length}
            </div>

            <div>
              <b>Número de pedidos cancelados: </b> {canceledOrders.length}
            </div>

            <div>
              <b>Valor Mínimo: </b>{' '}
              {Math.min(...completedOrdersPrice).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}{' '}
              <b>Valor Máximo: </b>{' '}
              {Math.max(...completedOrdersPrice).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </div>

            {firstOrder !== undefined ? (
              <div>
                <b>Data do primeiro pedido: </b>{' '}
                {moment(firstOrder.date).utcOffset('-0300').format('LLLL')}
              </div>
            ) : (
              <div>Primeiro pedido realizado.</div>
            )}
          </div>
        </div>

        {noGraphData !== '' ? null : (
          <div className="chart-container">
            <h3>Total de Compras por Dia</h3>
            <LinePlot
              data={data}
              color={['#eb5b1e']}
              showToolTipText={false}
              yFormatter={formatYAxis}
              xFormatter={formatXAxis}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: FraudSelectors.userSelector(state),
    isLoadingUser: FraudSelectors.gettingUserLoadingSelector(state),
    completedOrders: FraudSelectors.userCompletedOrdersSelector(state),
    completedOrdersPrice:
      FraudSelectors.userCompletedOrdersPriceSelector(state),
    canceledOrders: FraudSelectors.userCanceledOrdersSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getUser: (orderNumber: string) => {
      dispatch(FraudAction.getUser(orderNumber));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFraud);
