//flow
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Button from '../../common/components/Button';
// Components
import Select from 'react-select';
import { VehicleTypes } from '../constants/VehicleTypes';
import RouteItem from '../containers/RouteItem';
import VirtualRouteItem from '../containers/VirtualRouteItem';
//Style
import './RoutesList.css';

const VirtualRouteItemMemo = React.memo(VirtualRouteItem);
const RouteItemMemo = React.memo(RouteItem);

const routeStates = {
  open: 'aberto',
  pending: 'pendente',
  in_progress: 'em progresso',
  complete: 'completa',
};

const RoutesList = ({
  routes,
  virtualRoutes,
  vehicles,
  colors,
  virtualRoutesColors,
  autoRoutingInProgress,
  routeLoading,
  virtualRouteLoading,
  selectedVisitId,
  selectedVisitsId,
  vehicleById,
  visitById,
  handleVisitClick,
  handleClickRoute,
  handleAddVirtualRoute,
  handleGenerateVirtualRoutes,
  handleCreateManyRoutes,
}) => {
  // Determine which route states should be visible by default
  // Only the last one (Complete) is not visible at first
  const showRoutesInitialValue = new Array(
    Object.keys(routeStates).length,
  ).fill(true);
  showRoutesInitialValue[Object.keys(routeStates).length - 1] = false;

  const [showRoutesOnState, setShowRoutesOnState] = useState(
    showRoutesInitialValue,
  );
  const [showRoutingSection, setShowRoutingSection] = useState(false);
  const [vehiclesOptions, setVehiclesOptions] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  const getRoutesByState = state => {
    return _.filter(routes, r => {
      return r.state === state;
    });
  };

  useEffect(() => {
    if (!autoRoutingInProgress) {
      setSelectedVehicles([]);
      setVehiclesOptions([]);
    }
    setShowRoutingSection(autoRoutingInProgress);
  }, [autoRoutingInProgress]);

  const mapVehiclesToOptions = () =>
    _.filter(vehicles, v => v.vehicleType.slug !== 'takeout').map(vehicle => {
      return {
        value: Date.now() + Math.floor(1 + Math.random() * 1000),
        label: `${vehicle.licensePlate} - ${
          VehicleTypes[vehicle.vehicleType.slug]
        }`,
        val: vehicle.id,
      };
    });

  useEffect(() => {
    setVehiclesOptions(mapVehiclesToOptions());
  }, [vehicles]);

  const totalVisitsWeight = visitIds =>
    parseInt(
      visitIds.reduce(
        (sum, visitId) =>
          sum + (visitById(visitId) ? visitById(visitId).weight / 1000 : 0),
        0,
      ),
    );

  const totalVehiclesCapacity = vehicleIds =>
    parseInt(
      vehicleIds.reduce(
        (sum, vehicleId) => sum + vehicleById(vehicleId).weightLimit,
        0,
      ),
    );

  const handleSelectVehiclesChange = (e, option) => {
    let newSelectedVehicles = [...selectedVehicles];
    if (option.action === 'select-option') {
      const optionPos = vehiclesOptions.findIndex(
        opt => opt.value === option.option.value,
      );
      let newVehiclesOptions = [...vehiclesOptions];
      newVehiclesOptions[optionPos] = {
        ...newVehiclesOptions[optionPos],
      };
      newVehiclesOptions.splice(optionPos + 1, 0, {
        value: option.option.value + '_' + Date.now(),
        label: option.option.label,
        val: option.option.val,
      });
      newSelectedVehicles.push(option.option);
      setVehiclesOptions(newVehiclesOptions);
      setSelectedVehicles(newSelectedVehicles);
    } else if (
      option.action === 'remove-value' ||
      option.action === 'pop-value'
    ) {
      const optionPos = vehiclesOptions.findIndex(
        opt => opt.value === option.removedValue.value,
      );
      let newVehiclesOptions = vehiclesOptions.filter(
        opt => opt.value !== option.removedValue.value,
      );
      newVehiclesOptions[optionPos] = {
        ...newVehiclesOptions[optionPos],
      };
      newSelectedVehicles.splice(
        selectedVehicles.indexOf(option.removedValue),
        1,
      );
      setVehiclesOptions(newVehiclesOptions);
      setSelectedVehicles(newSelectedVehicles);
    } else if (option.action === 'clear') {
      setVehiclesOptions(mapVehiclesToOptions);
      setSelectedVehicles([]);
    }
  };

  const totalVehiclesLimit = totalVehiclesCapacity(
    selectedVehicles.map(vehicle => vehicle.val),
  );

  const visitsTotalWeight = totalVisitsWeight(selectedVisitsId);

  return (
    <div className="route-list-main" id="scrollable-route-list">
      <div>
        <div
          className={'route-list-main-expandable-menu'}
          onClick={() => {
            const newShowRoutingSection = !showRoutingSection;

            setShowRoutingSection(newShowRoutingSection);
          }}
        >
          <p>Roteamento</p>
          <i
            className={`fa fa-arrow-${showRoutingSection ? 'up' : 'down'}`}
            aria-hidden="true"
          ></i>
        </div>
        <div
          className={`route-list-items ${
            !showRoutingSection ? 'route-list-no-show-div' : ''
          }`}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
            style={{ width: '50%' }}
          >
            <div className="routing-form-main">
              <div className="routing-form-line-content-with-error">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  id="auto-routing-vehicles"
                  name="vehicles"
                  options={vehiclesOptions}
                  value={selectedVehicles}
                  placeholder="Selecionar veículos..."
                  className="routing-vehicles-select"
                  onChange={handleSelectVehiclesChange}
                />
              </div>
            </div>
          </form>
          <div className="virtual-route-section">
            <div className="virtual-route-info-weight-capacity">
              <div className="virtual-route-total-capacity">
                <span className="virtual-route-info-label">Capac. Total</span>
                <span
                  className={`virtual-route-info-value${
                    totalVehiclesLimit < visitsTotalWeight ? ' warning' : ''
                  }`}
                >
                  {totalVehiclesLimit}kg
                </span>
              </div>
              <div className="virtual-route-total-weight">
                <span className="virtual-route-info-label">Carga Total</span>
                <span className="virtual-route-info-value">
                  {visitsTotalWeight}kg
                </span>
              </div>
            </div>
            <div className="virtual-route-create-routes-button">
              <button
                onClick={() => handleAddVirtualRoute()}
                className="virtual-route-page-add-virtual-route-button"
                data-for="virtual-route-page-tooltip-add-virtual-route"
                data-tip=""
              >
                <ReactTooltip id="virtual-route-page-tooltip-add-virtual-route">
                  <p>Adicionar Rota Virtual</p>
                </ReactTooltip>

                <i className="fa fa-plus" aria-hidden="true"></i>
              </button>
              <div className="routing-button">
                <Button
                  onClick={() => {
                    handleGenerateVirtualRoutes(
                      selectedVehicles.map(option => option.val),
                      selectedVisitsId,
                    );
                  }}
                  disabled={
                    selectedVisitsId.length === 0 ||
                    selectedVehicles.length === 0
                  }
                  text="Gerar Rotas"
                  buttonType="button-quaternary"
                  loading={virtualRouteLoading}
                />
              </div>
            </div>
          </div>
          {(virtualRoutes && virtualRoutes.length === 0) ||
          !showRoutingSection ? (
            <p className="route-list-alert-no-routes">
              Nenhuma rota virtual gerada.
            </p>
          ) : (
            virtualRoutes &&
            virtualRoutes.constructor === Array &&
            virtualRoutes.map((virtualRoute, index) => (
              <VirtualRouteItemMemo
                key={index}
                virtualRoute={virtualRoute}
                color={
                  virtualRoutesColors[_.indexOf(virtualRoutes, virtualRoute)]
                }
                selectedVisitId={selectedVisitId}
                handleVisitClick={handleVisitClick}
                handleClickRoute={handleClickRoute}
              />
            ))
          )}
          <div className="routing-create-routes">
            <div className="routing-button" style={{ float: 'right' }}>
              <Button
                text="Criar Rotas"
                onClick={() => {
                  handleCreateManyRoutes(
                    Object.values(virtualRoutes).map(vr => {
                      return { visits: vr.visits, vehicleId: vr.vehicleId };
                    }),
                  );
                }}
                disabled={virtualRoutes.length === 0}
                buttonType="button-primary"
                loading={routeLoading}
              />
            </div>
          </div>
        </div>
      </div>
      {Object.keys(routeStates).map((routeState, index) => (
        <div key={index}>
          <div
            className={'route-list-main-expandable-menu'}
            onClick={() => {
              const newShowRoutesOnState = [...showRoutesOnState];
              newShowRoutesOnState[index] = !newShowRoutesOnState[index];
              setShowRoutesOnState(newShowRoutesOnState);
            }}
          >
            <p>{routeStates[routeState]}</p>
            <i
              className={`fa fa-arrow-${
                showRoutesOnState[index] ? 'up' : 'down'
              }`}
              aria-hidden="true"
            ></i>
          </div>
          <div
            className={`route-list-items ${
              !showRoutesOnState[index] ? 'route-list-no-show-div' : ''
            }`}
          >
            {getRoutesByState(routeState).length === 0 ||
            !showRoutesOnState[index] ? (
              <p className="route-list-alert-no-routes">
                Nenhuma rota neste estado.
              </p>
            ) : (
              getRoutesByState(routeState).map((route, i) => (
                <RouteItemMemo
                  key={route.id}
                  route={route}
                  color={colors[_.indexOf(routes, route)]}
                  selectedVisitId={selectedVisitId}
                  handleVisitClick={handleVisitClick}
                  handleClickRoute={handleClickRoute}
                />
              ))
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RoutesList;
